<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    },
    es: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    }
  },
  components: { Layout },
  data() {
    return {
      course: {
        loading: true,
        errored: false,
        empty: false,

        id: '',
        slug: this.$route.params.course,
        title: '',
        description: '',
        cover: '',
        progress: '',
      },

      modules: {
        slug: this.$route.params.modules,
        list: [],
      },

      lesson: {
        id: '',
        slug: this.$route.params.lessson,

        title: '',
        description: '',
        plataform: {
          company: '',
          code: '',
        },
      }
    };
  },
  methods: {
    getCourse() {
      this.course.loading = true

      api
        .get('course/' + this.course.slug)
        .then(response => {
          if (response.data.status=='success') {
            document.title = this.$route.meta.title + ' › ' + response.data.course.title

            this.course.loading = false
            this.course.id = response.data.course.id
            this.course.title = response.data.course.title
            this.course.description = response.data.course.description
            this.course.cover = response.data.course.cover
            this.course.progress = response.data.course.progress

            this.modules.list = response.data.modules

            this.showLesson()
          }
        })
        .catch(error => {
          this.course.errored = error
          this.course.loading = false
        })
        .finally(() => {
          this.course.loading = false
        })
    },
    setModule(slug) {
      if (slug == this.modules.slug) {
        this.modules.slug = ''
      } else {
        this.modules.slug = slug
      }
    },
    showLesson() {
      this.modules.list.map((module) => {
        module.lessons.map((lesson) => {
          if (this.$route.params.module == module.slug && this.$route.params.lesson == lesson.slug) {
            if (this.modules.slug != module.slug) {
              this.setModule(module.slug)
            }

            this.setLesson(lesson)
            return
          } else if (this.course.progress == '' && !this.lesson.slug) {
            if (this.modules.slug != module.slug) {
              this.setModule(module.slug)
            }

            this.setLesson(lesson)
            return;
          } else if (this.course.progress == lesson.id) {
            if (this.modules.slug != module.slug) {
              this.setModule(module.slug)
            }

            this.setLesson(lesson)
            return;
          }
        })

        if (!this.lesson.slug) {
          return
        }
      });
    },
    setLesson(lesson) {
      this.lesson.id = lesson.id
      this.lesson.slug = lesson.slug
      this.lesson.title = lesson.title
      this.lesson.description = lesson.description
      this.lesson.plataform.company = lesson.plataform.company
      this.lesson.plataform.code = lesson.plataform.code

      if (lesson.id != this.course.progress) {
        api
          .post('course/' + this.course.slug, {
            lesson: this.lesson.id
          })

        this.course.progress = lesson.id
      }
    }
  },
  mounted() {
    this.getCourse()
  },
  watch: {
    '$route.params.lesson': function () {
      this.showLesson()
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ course.title }}</h4>
        </div>
      </div>
    </div>
    <div v-if="course.loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
    </div>
    <div v-else class="row justify-content-center">
      <div class="col-xl-10">
        <div class="course mb-4">
          <div class="course-plataform">
            <div class="course-player">
              <iframe v-if="lesson.plataform.company == 'youtube'" class="rounded" width="560" height="315" :src="'https://www.youtube.com/embed/' + lesson.plataform.code + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="course-module">
            <div class="module-display">
              <div class="overflow-auto">
                <div v-if="modules.length == 0" class="p-4 text-center">
                  Nenhum módulo encontrado.
                </div>
                <div v-else>
                  <b-card no-body class="m-0" v-for="(m,id) in modules.list" :key="id">
                    <b-card-header header-tag="header" role="tab" class="lesson-header rounded p-0">
                      <button class="bg-soft-dark text-dark d-flex justify-content-between w-100 border-0 p-3 m-0 text-left" v-on:click="setModule(m.slug)">
                        <div class="sequence align-self-center">
                          <div class="rounded-circle bg-dark text-white text-center">
                            {{ id + 1 }}
                          </div>
                        </div>
                        <div class="title flex-fill px-3">
                          <h6 class="m-0 text-dark">{{ m.title }}</h6>
                          <small>{{ m.lessons.length }} aulas</small>
                        </div>
                        <div class="arrow align-self-center pr-2">
                          <img v-if="m.slug == modules.slug" class="down" src="@/assets/images/arrow-down.svg" />
                          <img v-else class="up" src="@/assets/images/arrow-up.svg" />
                        </div>
                      </button>
                    </b-card-header>
                    <b-collapse :id="m.slug" :visible="m.slug == modules.slug" accordion="modules">
                      <b-card-body class="lesson-body pt-2 px-4">
                        <div class="lesson-line m-0 pt-3" v-for="(l,index) in m.lessons" :key="index">
                          <div v-if="m.slug == modules.slug && l.slug == lesson.slug" class="d-flex lesson-link">
                            <div class="align-self-center lesson-circle-active pr-3" :class="[{'first': index == 0}, {'last': m.lessons.length == index + 1}]"><button></button></div>
                            <div class="align-self-center lesson-title"><router-link tag="a" :to="'/courses/' + course.slug + '/' + m.slug + '/' + l.slug" class="text-dark font-weight-bold">{{ l.title }}</router-link></div>
                          </div>
                          <div v-else class="d-flex lesson-link">
                            <div class="align-self-center lesson-circle-inactive pr-3" :class="[{'first': index == 0}, {'last': m.lessons.length == index + 1}]"><button></button></div>
                            <div class="align-self-center lesson-title"><router-link tag="a" :to="'/courses/' + course.slug + '/' + m.slug + '/' + l.slug" class="text-dark">{{ l.title }}</router-link></div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="course-lesson">
          <div class="card p-2">
            <div class="card-body p-4">
              <h4 class="text-dark mb-4">{{ lesson.title }}</h4>
              <div class="description pt-4 border-top border-soft-dark">
                <div v-html="lesson.description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>